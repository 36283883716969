export const MAIN_RED = "#FF6161";
export const MAIN_YELLOW = "#FFD747";
export const LIGHT_PURPLE_BLUE = "#b8b7fd";

export const SCALEOPS_COLORS = {
  black: "#131313",
  border: "#E5E7ED",
  strongBorder: "#c4c4c4",
  strongerBorder: "#666666",
  current: "currentColor",
  mainMenuBackground: "#19171D",
  mainMenuBackgroundHover: "#322F38",
  mainMenuSelected: "#D9D9D9",
  network: {
    ingress: "#b8b7fd",
    egress: "#E29979",
    total: "#E2EAFA",
  },
  primary: {
    pink: "#ff517a",
    purpleBlue: "#5353e0",
    lightPurpleBlue: "#b8b7fd",
  },
  greenPalette: {
    totalOptimize: "#52D39D",
    ownerless: "#FFD747",
    notHealthy: "#7B8CE6",
    notEvictable: "#00236B",
  },
  main: {
    burgundy: "#AC1D48",
    red: "#FF6161",
    orange: "#FF9141",
    yellow: "#FFD747",
    info: "#E2EAFA",
    green: "#52D39D",
    lightGreen: "#91d4b2",
    blue: "#3B8BFF",
    linkBlue: "#003366",
    deepBlue: "#5d78a5",
  },
  guideline: {
    darkRed: "#E4315E",
    lightRed: "#F7426F",
    darkYellow: "#EAB832",
    lightYellow: "#FFCC43",
    darkGreen: "#10AE6E",
    darkerGreen: "#0A8A5F",
    lightGreen: "#15BE7B",
    lighterGreen: "#AAE9CF",
    darkBlue: "#18135B",
    lessDarkBlue: "#2E2599",
    darkPurple: "#5353E0",
    darkPurpleHover: "#4242db",
    lessDarkPurple: "#6C70FF",
    lessDarkPurpleHover: "#5C5CFF",
    lightPurple: "#C7C7FF",
    lighterPurple: "#F0F0FF",
    darkBlack: "#292834",
    darkGray: "#EDECEA",
    lightGray: "#F7F7F7",
    lightBlue: "#E2EAFA",
  },
  background: {
    ghostWhite: "#F7F7F7",
    tab: "#EAECEE",
    chip: "#EBECF2",
    chipHover: "#D5D6DD",
    gray: "#CCCCCC",
    chipActive: "#B4B3CB",
    scrollbar: "#C8C6D5",
    darkChipHover: "#70829f",
    kubernetes: "#326DE6",
    checkbox: "#DBDBFF",
  },
  text: {
    disable: "#C6C6D6",
    darkGray: "#898793",
    lightBlack: "#595959",
  },
  status: {
    skipped: "#898793",
    pending: "#322F38",
    inProgress: "#52D39D",
    completed: "#3B8B61",
    failed: "#FF6161",
  },
  events: {
    eviction: "#9BCDD2",
    liveness: "#bde3b4",
    throttling: "#f7e0ab",
    stressed: "#FF8551",
    oom: "#fac2c2",
    oomKubelet: "#ffb58f",
    oomLimit: "#fe964e",
    oomNode: "#ff6c14",
    cpuFastReaction: "#bb76e3",
    memoryFastReaction: "#bb76e3",
    fastReaction: "#bb76e3",
    cpuThrottling: "#AC9111",
  },
  workload: {
    replicas: "#b8b7fd",
    readyReplicas: "#7fdfb9",
    minReplicas: "#5c8dba",
    maxReplicas: "#5353E0",
    desiredReplicas: "#7626AD",
  },
  randomColors: [
    "#9BCDD2",
    "#f7e0ab",
    "#FF8551",
    "#fac2c2",
    "#b8b7fd",
    "#7fdfb9",
    "#bde3b4",
    "#FF6161",
    "#FFD747",
    "#3B8BFF",
    "#5353E0",
    "#52D39D",
    "#FF9141",
    "#AC1D48",
    "#FF517A",
    "#A6A6A6",
  ],
  workloadTags: [
    "#bde3b4",
    "#ffc4e4",
    "#ffb291",
    "#eefcb3",
    "#a2d2ff",
    "#c1af11",
    "#ffbfbf",
    "#ffeba3",
    "#efacfc",
    "#82d9d9",
    "#8cfffa",
    "#e0c5fc",
    "#ffd12b",
    "#83c9c6",
    "#faace3",
  ],
};
