import { useRef, useState, useLayoutEffect } from "react";
import { useQueryParam, ArrayParam, BooleanParam, StringParam } from "use-query-params";
import { Chip } from "@mui/material";
import { GridFilterListIcon } from "@mui/x-data-grid";
import { LogicalOperator } from "../api/fetcher";
import MultiSelect from "./MultiSelect";
import { CustomSelectOptions } from "./useSelectSearch";

export interface MultiSelectByQueryParamsProps {
  options: string[] | undefined;
  queryKey: string;
  name?: string;
  excludeQueryKey?: string;
  logicalOperatorQueryKey?: string;
  dataTestId?: string;
  icon?: React.ReactNode;
  capitalize?: boolean;
  tooltipPrefix?: React.ReactNode;
  hasVirtualizedList?: boolean;
  hasIsExclude?: boolean;
  hasLogicalAndOR?: boolean;
  hasSelectAllAndClearSelection?: boolean;
  isSearchable?: boolean;
  hasCustomIcon?: boolean;
  optionRenderFunction?: (
    option: string,
    index: number,
    selected: (string | undefined)[],
    dataTestId?: string,
    capitalize?: boolean
  ) => JSX.Element;
  renderValue?: (selected: (string | undefined)[]) => string;
  label?: React.ReactNode;
  disableSort?: boolean;
  enableAddCustomValue?: boolean;
  customSelectOptions?: CustomSelectOptions;
  modifyOptionText?: (option: string) => string;
}

const MultiSelectByQueryParams = ({
  options,
  queryKey,
  excludeQueryKey,
  logicalOperatorQueryKey,
  dataTestId,
  icon,
  name,
  capitalize,
  tooltipPrefix,
  hasVirtualizedList,
  hasIsExclude = true,
  hasLogicalAndOR = false,
  hasSelectAllAndClearSelection = true,
  isSearchable = true,
  hasCustomIcon = true,
  optionRenderFunction,
  renderValue,
  label,
  disableSort,
  enableAddCustomValue,
  customSelectOptions,
  modifyOptionText,
}: MultiSelectByQueryParamsProps) => {
  const [customValues, setCustomValues] = useState<string[]>(
    enableAddCustomValue ? (JSON.parse(sessionStorage.getItem(`${queryKey}-custom-values`) || "[]") as string[]) : []
  );

  excludeQueryKey = excludeQueryKey ?? `is${queryKey.charAt(0).toUpperCase() + queryKey.slice(1)}Exclude`;
  logicalOperatorQueryKey = logicalOperatorQueryKey ?? `${queryKey.toLowerCase()}LogicalOperator`;
  dataTestId = dataTestId ?? `${queryKey}-filter-chip`;
  icon = icon ?? <GridFilterListIcon fontSize={"small"} />;
  name = name ?? queryKey;

  const [queryParams, setQueryParams] = useQueryParam(queryKey, ArrayParam);
  const [isQueryParamsExcluded, setIsQueryParamsExcluded] = useQueryParam(excludeQueryKey, BooleanParam);
  const [logicalOperator, setLogicalOperator] = useQueryParam(logicalOperatorQueryKey, StringParam);

  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);

  const addCustomValue = (searchTerm: string) => {
    if (searchTerm && !options?.includes(searchTerm)) {
      setCustomValues([...customValues, searchTerm]);
      const isAlreadyIncludeInCustomValues = customValues.includes(searchTerm);

      !isAlreadyIncludeInCustomValues &&
        sessionStorage.setItem(`${queryKey}-custom-values`, JSON.stringify([...customValues, searchTerm]));
    }
  };

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref.current, icon]);

  const isQueryParamsSelected = queryParams && queryParams?.length > 0;

  return (
    <MultiSelect
      label={label}
      optionRenderFunction={optionRenderFunction}
      dataTestId={dataTestId}
      listHeight={"500px"}
      listWidth={"600px"}
      selected={queryParams?.map((param) => param || undefined).filter((param) => param !== undefined) || []}
      width={width}
      setSelected={setQueryParams as (props: (string | undefined)[]) => void}
      options={[...customValues, ...(options ?? [])]}
      sort={disableSort ? undefined : "asc"}
      customIcon={
        hasCustomIcon ? (
          <div ref={ref}>
            <Chip
              data-testid={dataTestId}
              variant={"outlined"}
              sx={{
                background: isQueryParamsSelected ? "#595959" : undefined,
                color: isQueryParamsSelected ? "white" : "rgba(0, 0, 0, 0.87)",
                borderColor: "rgba(0, 0, 0, 0.87)",
                marginTop: "-4px",
              }}
              label={
                <div className="flex items-center justify-center">
                  {icon}
                  {name}
                </div>
              }
            />
          </div>
        ) : undefined
      }
      isExclude={hasIsExclude ? isQueryParamsExcluded : undefined}
      setIsExclude={hasIsExclude ? setIsQueryParamsExcluded : undefined}
      logicalOperator={
        hasLogicalAndOR && logicalOperator !== undefined ? (logicalOperator as LogicalOperator) : undefined
      }
      setLogicalOperator={hasLogicalAndOR ? setLogicalOperator : undefined}
      capitalize={capitalize}
      tooltipPrefix={tooltipPrefix}
      hasVirtualizedList={hasVirtualizedList}
      hasSelectAllAndClearSelection={hasSelectAllAndClearSelection}
      isSearchable={isSearchable}
      renderValue={renderValue}
      addCustomValueFnc={enableAddCustomValue ? addCustomValue : undefined}
      customSelectOptions={customSelectOptions}
      modifyOptionText={modifyOptionText}
    />
  );
};

export default MultiSelectByQueryParams;
